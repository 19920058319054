window.addEventListener('DOMContentLoaded', function () {
    var productInputSelect = document.querySelectorAll('input[name="purchase[product_id]"]');

    for (var i = 0; i < productInputSelect.length; i++) {
        if (productInputSelect[i].checked === true) {

            var target = productInputSelect[i];

            var productData = {};
            productData.content_name = target.getAttribute('data-product-name');
            productData.content_type = "product";
            productData.content_ids = target.getAttribute('value');
            productData.currency = target.getAttribute('data-product-currency-code');
            productData.value = target.getAttribute('data-product-amount');

            sessionStorage.setItem("productData", JSON.stringify(productData));
        }
        productInputSelect[i].addEventListener("click", function (e) {
            var target = e.target;
            var productData = {};
            productData.content_name = target.getAttribute('data-product-name');
            productData.content_type = "product";
            productData.content_ids = target.getAttribute('value');
            productData.currency = target.getAttribute('data-product-currency-code');
            productData.value = target.getAttribute('data-product-amount');

            sessionStorage.setItem("productData", JSON.stringify(productData));

        }, false);
        productInputSelect[i].addEventListener("touchstart", function (e) {
            var target = e.target;
            var productData = {};
            productData.content_name = target.getAttribute('data-product-name');
            productData.content_type = "product";
            productData.content_ids = target.getAttribute('value');
            productData.currency = target.getAttribute('data-product-currency-code');
            productData.value = target.getAttribute('data-product-amount');

            sessionStorage.setItem("productData", JSON.stringify(productData));
        }, false);
    }
});

var script = document.querySelectorAll('[id^=monkediaPixel]');
var actions = [];
for (var i = 0; i < script.length; i++) {
    actions.push(setVar(script[i].getAttribute("data-action")));
}
// var action = setVar(script.getAttribute("data-action"));
var pixels = setVar(script[0].getAttribute("data-pixels"));

if (pixels == "") {
    throw new Error('Pixel(s) must be set.');
}

// explode on pixels
var multiPixels = pixels.split("|");

!function (f, b, e, v, n, t, s) {
    if (f.fbq)return;
    n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq)f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
}(window,
    document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

for (var i = 0; i < multiPixels.length; i++) {
    fbq('init', multiPixels[i]);
}

fbq('track', "PageView");
var productData = JSON.parse(sessionStorage.getItem("productData"));
for (var e = 0; e < actions.length; e++) {
    if (checkSession(actions[e])){
        switch (actions[e]) {
            case 'Purchase':
                fbq('track', 'Purchase', {
                        content_name: setVar(productData.content_name),
                        content_ids: [setVar(productData.content_ids)],
                        content_type: setVar(productData.content_type),
                        value: setVar(productData.value),
                        currency: setVar(productData.currency)
                    }
                );
                break;
            case 'ViewContent':
                fbq('track', actions[e]);
                break;
            case 'Search':
                fbq('track', actions[e]);
                break;
            case 'AddToCart':
                fbq('track', actions[e]);
                break;
            case 'AddToWishList':
                fbq('track', actions[e]);
                break;
            case 'InitiateCheckout':
                fbq('track', actions[e]);
                break;
            case 'AddPaymentInfo':
                fbq('track', actions[e]);
                break;
            case 'Lead':
                fbq('track', actions[e]);
                break;
            case 'CompleteRegistration':
                fbq('track', actions[e]);
                break;
            default:
                fbq('trackCustom', actions[e]);
                break;
        }
    }
}

function setVar(variable) {
    if (typeof variable === 'undefined' || variable === null) {
        return "";
    } else {
        return variable;
    }
}

function checkSession(eventName){
    if ((localStorage.getItem(eventName+"-"+window.location.href) != "1")&&(eventName != "")){
        localStorage.setItem(eventName+"-"+window.location.href, "1");
        return true;
    }
    else {
        return false;
    }
}


// try to load jungle pixel if there are any custom parameters
var jungleLoaded = false;
var tryJungle = null;
if (getUrlParameter('m_ac') !== null) {
  tryJungle = setInterval(function () {
    loadJunglePixel();
  }, 50);
}

// function to load the Jungle custom event
function loadJunglePixel() {
  jungleLoaded = true;
  try {
    fbq('trackCustom', 'Jungle', {
      m_cl: getUrlParameter('m_cl'),
      m_vr: getUrlParameter('m_vr'),
      m_ac: getUrlParameter('m_ac'),
      m_cm: getUrlParameter('m_cm'),
      m_op: getUrlParameter('m_op'),
      m_gl: getUrlParameter('m_gl'),
      m_pl: getUrlParameter('m_pl'),
      m_pw: getUrlParameter('m_pw'),
      m_tm: getUrlParameter('m_tm'),
      m_cg: getUrlParameter('m_cg'),
      m_ci: getUrlParameter('m_ci'),
      m_ty: getUrlParameter('m_ty'),
      m_sy: getUrlParameter('m_sy'),
      m_cp: getUrlParameter('m_cp')
    });
  } catch (err) {
    jungleLoaded = false;
  }

  if (jungleLoaded) {
    clearInterval(tryJungle);
  }
}

// function to get url parameter values by name
function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
